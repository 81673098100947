import { ClassicPreset } from "rete";
import { socket } from "../sockets/sockets";


export class CustomNode extends ClassicPreset.Node {
  // If you want custom height add this
  // width = 180;
  // height = 260;

  constructor(name = "Node", inputIds = [], outputIds = []) {
    super(name); // Set the custom node name

    // Create inputs with custom IDs
    inputIds.forEach(id => {
      this.addInput(id, new ClassicPreset.Input(socket, `Input ${id}`));
    });

    // Create outputs with custom IDs
    outputIds.forEach(id => {
      this.addOutput(id, new ClassicPreset.Output(socket, `Output ${id}`));
    });
  }
}



export class CustomConnection<A extends ClassicPreset.Node, B extends ClassicPreset.Node> extends ClassicPreset.Connection<A, B> {
  isMagnetic = false;

  constructor(source: A, sourceOutput: string, target: B, targetInput: string) {
    super(source, sourceOutput, target, targetInput);
    this.isMagnetic = false;
  }
}
