import React from "react";
import { createRoot } from "react-dom/client";
import { NodeEditor, GetSchemes, ClassicPreset } from "rete";
import { AreaPlugin, AreaExtensions } from "rete-area-plugin";
import { ConnectionPlugin, Presets as ConnectionPresets } from "rete-connection-plugin";
import { ReactPlugin, Presets, ReactArea2D } from "rete-react-plugin";
import { UseMagneticConnection, MagneticConnection } from "./magnetic-connection";
import { CustomNode, CustomConnection } from "./nodes/CustomNodes"; // Importing CustomNode and CustomConnection
import { exportEditorContent } from "./exporting/exportLogic"; // Importing the export logic
import { createTopBar } from "./components/TopBar"; // Importing the TopBar logic

type Schemes = GetSchemes<CustomNode, CustomConnection<CustomNode, CustomNode>>;
type AreaExtra = ReactArea2D<Schemes>;

export async function createEditor(container: HTMLElement) {
  const editor = new NodeEditor<Schemes>();
  const area = new AreaPlugin<Schemes, AreaExtra>(container);
  const connection = new ConnectionPlugin<Schemes, AreaExtra>();
  const render = new ReactPlugin<Schemes, AreaExtra>({ createRoot });

  /* eslint-disable @typescript-eslint/ban-ts-comment */

  // @ts-ignore
  AreaExtensions.selectableNodes(area, AreaExtensions.selector(), {
    accumulating: AreaExtensions.accumulateOnCtrl()
  });

  // @ts-ignore
  render.addPreset(
    Presets.classic.setup({
      customize: {
        connection(data) {
          if (data.payload.isMagnetic) return MagneticConnection;
          return Presets.classic.Connection;
        }
      }
    })
  );

  connection.addPreset(ConnectionPresets.classic.setup());

  // @ts-ignore
  editor.use(area);
  // @ts-ignore
  area.use(connection);
  // @ts-ignore
  area.use(render);

  // @ts-ignore
  AreaExtensions.simpleNodesOrder(area);

  // @ts-ignore
  UseMagneticConnection(connection, {
    async createConnection(from, to) {
      if (from.side === to.side) return;
      const [source, target] = from.side === "output" ? [from, to] : [to, from];
      const sourceNode = editor.getNode(source.nodeId);
      const targetNode = editor.getNode(target.nodeId);

      await editor.addConnection(
        new CustomConnection(
          sourceNode,
          source.key as never,
          targetNode,
          target.key as never
        )
      );
    },
    display(from, to) {
      return from.side !== to.side;
    },
    offset(socket, position) {
      const socketRadius = 10;

      return {
        x: position.x + (socket.side === "input" ? -socketRadius : socketRadius),
        y: position.y
      };
    }
  });

  // Keep track of the selected node
  let selectedNode: CustomNode | null = null;

  const getSelectedNode = (node: CustomNode | null) => {
   return  selectedNode;
  };

  const setSelectedNode = (node: CustomNode | null) => {
    selectedNode = node;
  };

  const { addButton, deleteButton, exportButton, topBar } = createTopBar(editor, area, container, getSelectedNode, setSelectedNode);

  // Handle node selection using area.nodePointerDown and area.click
  area.addPipe(context => {
    //console.log('Context:', context); // Log the context object for debugging

    if (context.type === 'nodepicked') {
      selectedNode = context.data as CustomNode;
      deleteButton.style.display = 'block'; // Show delete button
    } else if (context.type === 'pointerdown') {
      // Check if the target is a button, if so, don't do anything
      if (context.data.event && (context.data.event.target as HTMLElement).tagName.toLowerCase() === 'button') {
        return context;
      }
      
      selectedNode = null;
      deleteButton.style.display = 'none'; // Hide delete button
    }
    return context;
  });

  return {
    destroy: () => {
      area.destroy();
      addButton.remove();
      deleteButton.remove();
      exportButton.remove();
      topBar.remove();
    }
  };
}
