// exportLogic.js
import { AreaPlugin, AreaExtensions } from "rete-area-plugin";
import { CustomNode } from "../nodes/CustomNodes"; // Importing CustomNode and CustomConnection
import { socket } from '../sockets/sockets'; // Adjust the import path accordingly
import { GetSchemes, ClassicPreset, NodeEditor } from "rete";
import { CustomConnection } from "../nodes/CustomNodes"; // Importing CustomNode and CustomConnection

export function exportEditorContent(editor, area) {
  const data = { nodes: [], connections: [] };
  const nodes = editor.getNodes();

  // Export nodes
  for (const node of nodes) {
    const nodeView = area.nodeViews.get(node.id); // Get the node view for the current node
    const position = nodeView ? nodeView.position : { x: 0, y: 0 }; // Default to (0, 0) if not found

    const inputsEntries = Object.entries(node.inputs || {}).map(([key, input]) => {
      return [key, {
        id: input.id,
        label: input.label,
        socket: {
          name: input.socket.name
        }
      }];
    });

    const outputsEntries = Object.entries(node.outputs || {}).map(([key, output]) => {
      return [key, {
        id: output.id,
        label: output.label,
        socket: {
          name: output.socket.name
        }
      }];
    });

    const controlsEntries = Object.entries(node.controls || {}).map(([key, control]) => {
      return [key, {
        id: control.id,
        value: control.getData(),
        type: control.constructor.name
      }];
    });

    const nodeData = {
      id: node.id,
      name: node.name,
      position: position, // Include the position from the node view
      data: node.data,
      inputs: Object.fromEntries(inputsEntries),
      outputs: Object.fromEntries(outputsEntries),
      controls: Object.fromEntries(controlsEntries)
    };

    // Include any other custom properties or methods defined in the node
    Object.keys(node).forEach(prop => {
      if (!(prop in nodeData)) {
        nodeData[prop] = node[prop];
      }
    });

    data.nodes.push(nodeData);
  }

  // Export connections from editor.connections
  editor.connections.forEach(connection => {
    data.connections.push({
      id: connection.id,
      source: {
        node: connection.source, // source node ID
        output: connection.sourceOutput // output key from source node
      },
      target: {
        node: connection.target, // target node ID
        input: connection.targetInput // input key from target node
      }
    });
  });

  // Create a Blob from the JSON string
  const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });

  // Create a download link
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'editor-content.json';
  a.click();

  // Clean up the URL object
  URL.revokeObjectURL(url);
}



// importLogic.js
export async function importEditorContent(data, editor, area) {
  // Step 0: Clear the editor before importing new data
  editor.clear();

  const nodesMap = new Map();
  const addNodeTasks = [];

  // Step 1: Reconstruct Nodes
  for (const { id, label, inputs, outputs, controls, position } of data.nodes) {
    const node = new ClassicPreset.Node(label);

    node.id = id;

    // Reconstruct inputs using the ClassicPreset.Socket and ClassicPreset.Input
    Object.entries(inputs).forEach(([key, input]) => {
      const socket = new ClassicPreset.Socket(input.socket.name);
      const inp = new ClassicPreset.Input(socket, input.label);

      inp.id = input.id;

      node.addInput(key, inp); // Correctly add input to the node
    });

    // Reconstruct outputs using the ClassicPreset.Socket and ClassicPreset.Output
    Object.entries(outputs).forEach(([key, output]) => {
      const socket = new ClassicPreset.Socket(output.socket.name);
      const out = new ClassicPreset.Output(socket, output.label);

      out.id = output.id;

      node.addOutput(key, out); // Correctly add output to the node
    });

    // Reconstruct controls
    Object.entries(controls || {}).forEach(([key, control]) => {
      if (!control) return;

      let ctrl;
      if (control.__type === "ClassicPreset.InputControl") {
        ctrl = new ClassicPreset.InputControl(control.type, {
          initial: control.value,
          readonly: control.readonly
        });
      } else {
        // Add handling for other control types if necessary
        console.warn(`Unrecognized control type: ${control.__type}`);
        return;
      }

      node.addControl(key, ctrl);
    });

    // Add the reconstructed node to the editor and apply the position
    const task = editor.addNode(node).then(async () => {
      if (position && position.x !== undefined && position.y !== undefined) {
        await area.translate(node.id, { x: position.x, y: position.y }); // Adjust the position of the new node
      }
    });
    addNodeTasks.push(task);

    nodesMap.set(node.id, node);
  }

  await Promise.all(addNodeTasks);

  // Step 2: Reconstruct Connections
  const addConnectionTasks = [];

  for (const connectionData of data.connections) {
    const sourceNode = nodesMap.get(connectionData.source.node);
    const targetNode = nodesMap.get(connectionData.target.node);

    if (sourceNode && targetNode) {
      const sourceOutput = sourceNode.outputs[connectionData.source.output];
      const targetInput = targetNode.inputs[connectionData.target.input];

      if (sourceOutput && targetInput) {
        const sourceNodeObj = editor.getNode(sourceNode.id);
        const targetNodeObj = editor.getNode(targetNode.id);

        await editor.addConnection(
          new CustomConnection(
            sourceNodeObj,
            connectionData.source.output,
            targetNodeObj,
            connectionData.target.input
          )
        );
      } else {
        console.warn(`Cannot find output or input for connection ${connectionData.id}`);
      }
    } else {
      console.warn(`Cannot find nodes for connection ${connectionData.id}`);
    }
  }

  await Promise.all(addConnectionTasks);

  // Adjust the zoom to fit all nodes
  AreaExtensions.zoomAt(area, editor.getNodes());

  console.log('Nodes and connections imported successfully.');
}
