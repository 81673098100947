import { AreaPlugin, AreaExtensions } from "rete-area-plugin";
import { CustomNode } from "../nodes/CustomNodes"; // Importing CustomNode and CustomConnection
import { exportEditorContent } from "../exporting/exportLogic"; // Importing the export logic
import { importEditorContent } from "../exporting/exportLogic"; // Importing the export logic

import { createInputDialog } from "./InputDialog"; // Importing the dialog component

export function createTopBar(editor, area, container, getSelectedNode, setSelectedNode) {
    const topBar = document.createElement('div');
    topBar.style.position = 'fixed';
    topBar.style.top = '0';
    topBar.style.left = '0';
    topBar.style.width = '100%';
    topBar.style.backgroundColor = '#333';
    topBar.style.padding = '5px';
    topBar.style.display = 'flex';
    topBar.style.justifyContent = 'space-between';
    topBar.style.zIndex = '1000';

    const addButton = document.createElement('button');
    addButton.textContent = 'Agregar Nodo';
    addButton.style.padding = '5px';
    addButton.style.marginRight = '10px';
    addButton.style.backgroundColor = '#007BFF';
    addButton.style.color = 'white';
    addButton.style.border = 'none';
    addButton.style.borderRadius = '5px';
    addButton.style.cursor = 'pointer';

    addButton.onclick = async () => {
        createInputDialog(async (nodeName, inputs, outputs) => {
            const newNode = new CustomNode(nodeName, inputs, outputs); // Pass node name and inputs/outputs with custom IDs
            await editor.addNode(newNode);
            await area.translate(newNode.id, { x: 200, y: 200 });
            AreaExtensions.zoomAt(area, editor.getNodes());
        });
    };

    const importButton = document.createElement('button');
    importButton.textContent = 'Importar';
    importButton.style.padding = '5px';
    importButton.style.marginRight = '10px';
    importButton.style.backgroundColor = '#28a745';
    importButton.style.color = 'white';
    importButton.style.border = 'none';
    importButton.style.borderRadius = '5px';
    importButton.style.cursor = 'pointer';

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.json';
    fileInput.style.display = 'none';

    fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const json = JSON.parse(e.target.result);
                    await importEditorContent(json, editor, area);
                } catch (error) {
                    console.error('Failed to import nodes:', error);
                }
            };
            reader.readAsText(file);
        }
    };

    importButton.onclick = () => {
        fileInput.click();
    };

    const deleteButton = document.createElement('button');
    deleteButton.textContent = 'Eliminar Nodo';
    deleteButton.style.padding = '5px';
    deleteButton.style.backgroundColor = '#FF0000';
    deleteButton.style.color = 'white';
    deleteButton.style.border = 'none';
    deleteButton.style.borderRadius = '5px';
    deleteButton.style.cursor = 'pointer';
    deleteButton.style.display = 'none';

    deleteButton.onclick = () => {
        let selectedNode = getSelectedNode();
        if (selectedNode) {
            editor.getConnections().forEach(connection => {
                if (connection.source === selectedNode.id || connection.target === selectedNode.id) {
                    editor.removeConnection(connection.id);
                }
            });

            editor.removeNode(selectedNode.id);
            setSelectedNode(null);
            deleteButton.style.display = 'none';
        }
    };

    const exportButton = document.createElement('button');
    exportButton.textContent = 'Guardar';
    exportButton.style.padding = '5px';
    exportButton.style.marginRight = '20px';
    exportButton.style.backgroundColor = '#28a745';
    exportButton.style.color = 'white';
    exportButton.style.border = 'none';
    exportButton.style.borderRadius = '5px';
    exportButton.style.cursor = 'pointer';

    exportButton.onclick = () => {
        exportEditorContent(editor, area);
    };

    const leftContainer = document.createElement('div');
    leftContainer.style.display = 'flex';
    leftContainer.appendChild(addButton);
    leftContainer.appendChild(importButton);
    leftContainer.appendChild(deleteButton);

    const rightContainer = document.createElement('div');
    rightContainer.style.marginRight = '10px';
    rightContainer.appendChild(exportButton);

    topBar.appendChild(leftContainer);
    topBar.appendChild(rightContainer);

    container.appendChild(topBar);
    container.appendChild(fileInput);

    return { addButton, importButton, deleteButton, exportButton, topBar };
}
