// InputDialog.js
// InputDialog.js
export function createInputDialog(onConfirm) {
    const dialog = document.createElement('div');
    dialog.style.position = 'fixed';
    dialog.style.top = '50%';
    dialog.style.left = '50%';
    dialog.style.transform = 'translate(-50%, -50%)';
    dialog.style.backgroundColor = 'white';
    dialog.style.padding = '20px';
    dialog.style.width = '300px';
    dialog.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
    dialog.style.zIndex = '1001';
    dialog.style.borderRadius = '10px';

    const title = document.createElement('h3');
    title.textContent = 'Configure Node';
    title.style.marginBottom = '15px';
    title.style.textAlign = 'center';
    dialog.appendChild(title);

    // Node name input
    const nameContainer = document.createElement('div');
    nameContainer.style.marginBottom = '15px';

    const nameLabel = document.createElement('label');
    nameLabel.textContent = 'Nombre del Nodo:';
    nameLabel.style.fontWeight = 'bold';
    nameContainer.appendChild(nameLabel);

    const nameInput = document.createElement('input');
    nameInput.type = 'text';
    nameInput.placeholder = 'Nombre del Nodo';
    nameInput.style.marginTop = '5px';
    nameInput.style.width = '100%';
    nameInput.style.padding = '5px';
    nameInput.style.boxSizing = 'border-box';
    nameInput.style.border = '1px solid #ccc';
    nameInput.style.borderRadius = '4px';

    nameContainer.appendChild(nameInput);
    dialog.appendChild(nameContainer);

    const inputList = [];
    const outputList = [];

    // Create a container for input fields
    const inputsContainer = document.createElement('div');
    inputsContainer.style.marginBottom = '10px';

    const inputsLabel = document.createElement('label');
    inputsLabel.textContent = 'Entradas:';
    inputsLabel.style.fontWeight = 'bold';
    inputsContainer.appendChild(inputsLabel);

    // Add Input button
    const addInputButton = document.createElement('button');
    addInputButton.textContent = 'Agregar Entrada';
    addInputButton.style.marginLeft = '10px';
    addInputButton.style.padding = '5px';
    addInputButton.style.backgroundColor = '#007BFF';
    addInputButton.style.color = 'white';
    addInputButton.style.border = 'none';
    addInputButton.style.borderRadius = '5px';
    addInputButton.style.cursor = 'pointer';

    const addCustomField = (container, list, placeholder) => {
        const inputField = document.createElement('input');
        inputField.type = 'text';
        inputField.placeholder = placeholder;
        inputField.style.marginTop = '5px';
        inputField.style.width = '100%';
        inputField.style.padding = '5px';
        inputField.style.boxSizing = 'border-box';
        inputField.style.border = '1px solid #ccc';
        inputField.style.borderRadius = '4px';

        list.push(inputField);
        container.appendChild(inputField);
    };

    // Functionality for "Agregar Entrada"
    addInputButton.onclick = () => {
        addCustomField(inputsContainer, inputList, 'ID de Entrada');
    };

    inputsContainer.appendChild(addInputButton);
    dialog.appendChild(inputsContainer);

    // Create a container for output fields
    const outputsContainer = document.createElement('div');
    outputsContainer.style.marginBottom = '10px';

    const outputsLabel = document.createElement('label');
    outputsLabel.textContent = 'Salidas:';
    outputsLabel.style.fontWeight = 'bold';
    outputsContainer.appendChild(outputsLabel);

    // Add Output button
    const addOutputButton = document.createElement('button');
    addOutputButton.textContent = 'Agregar Salida';
    addOutputButton.style.marginLeft = '10px';
    addOutputButton.style.padding = '5px';
    addOutputButton.style.backgroundColor = '#28a745';
    addOutputButton.style.color = 'white';
    addOutputButton.style.border = 'none';
    addOutputButton.style.borderRadius = '5px';
    addOutputButton.style.cursor = 'pointer';

    // Functionality for "Agregar Salida"
    addOutputButton.onclick = () => {
        addCustomField(outputsContainer, outputList, 'ID de Salida');
    };

    outputsContainer.appendChild(addOutputButton);
    dialog.appendChild(outputsContainer);

    // Confirm button
    const confirmButton = document.createElement('button');
    confirmButton.textContent = 'Confirmar';
    confirmButton.style.marginTop = '20px';
    confirmButton.style.padding = '10px';
    confirmButton.style.width = '100%';
    confirmButton.style.backgroundColor = '#28a745';
    confirmButton.style.color = 'white';
    confirmButton.style.border = 'none';
    confirmButton.style.borderRadius = '5px';
    confirmButton.style.cursor = 'pointer';

    confirmButton.onclick = () => {
        const nodeName = nameInput.value.trim();
        const inputs = inputList.map(inputField => inputField.value.trim());
        const outputs = outputList.map(outputField => outputField.value.trim());

        // Ensure no spaces in the IDs
        if (!nodeName) {
            alert('El nombre del nodo no puede estar vacío.');
            return;
        }

        if (inputs.some(id => id.includes(' ')) || outputs.some(id => id.includes(' '))) {
            alert('Los IDs no pueden contener espacios.');
            return;
        }

        onConfirm(nodeName, inputs, outputs);
        document.body.removeChild(dialog); // Remove the dialog after confirmation
    };

    dialog.appendChild(confirmButton);

    document.body.appendChild(dialog);
}

